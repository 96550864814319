<div class="opiniones">
    <h2>Opiniones de clientes satisfechos</h2>
    <div class="container-opiniones">
      <swiper-container
        class="mySwiper"
        navigation="true"
        space-between="30"
        centered-slides="true"
        autoplay-delay="15000"
        autoplay-disable-on-interaction="false"
      ><!-- primeros comentarios -->
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/andrea.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Andrea Palma</h6>
            <p>
            El sabor que tienen las hamburguesas es buenísimo, tienen muy buen tamaño y 
            me parece que el precio es súper justo, hay cervezas nacionales, de exportación y artesanales. 
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/ivel.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Ivel Urbina Medina</h6>
            <p>
              Me gusto mucho el ambiente y la decoración del sitio, pedimos hamburguesas y de verdad 
              estaban bien ricas, además de haber bastante variedad.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/jaime.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Jaime Martinez</h6>
            <p>
              Muy buen lugar para disfrutar de unas alitas, una hamburguesa y papas,
              puedes pedir de todo tipo de cervezas, y hay varias cervezas importadas y tambien hay cervezas artesanales,
              el servicio es muy bueno y los precios muy accesibles
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/oscar.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Oscar Peréz</h6>
            <p>
              Excelente lugar para degustar hamburguesas y alitas, el personal es muy amable, 
              el lugar es limpio y cuenta con las medidas de prevención de contingencia, 
              es cómodo y tranquilo con buen ambiente, tiene gran variedad de cervezas internacionales. 
          
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/maddie.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Maddie</h6>
            <p>
              Hamburguesas de muy buena calidad y grande lo que uno espera por el precio. Meseras super amables! recomiendo el lugar.
            </p>
          </div>
        </swiper-slide>
        <!-- segundos comentarios -->
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/christian.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Christian Obregón</h6>
            <p>
              Las mejores alitas que he comido en la vida, el servicio es rápido y muy amable, buena variedad de cervezas. Muy recomendable. 
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/uriel.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Uriel Zahuantitla</h6>
            <p>
              Para lo que nos gustan las alitas y las cervezas de variedad y por supuesto el Rock es el lugar ideal, excelente atmosfera, la cerveza Liefmans con fresas es una maravilla. Un lugar 100% recomendado.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/samuel.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Samuel Alejandro Carmona Miranda</h6>
            <p>
              Comida muy rica en todos sus platillos, buena atención y buen ambiente con música de todo tipo,muy recomendado.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/yeimy.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Yeimy Jatziry Delgado</h6>
            <p>
              En general la comida tiene buen sabor está bien cocida y el servicio es bueno. La mayoría de sus cervezas de sabor no tienen un elevado grado de alcohol  
          
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/aura.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>Aura Ortiz</h6>
            <p>
              Muy rico y las cervezas dulce te las dan con fruta fresca. Recomiendo la hamburguesa con salsa de pizza. Delicioso!
            </p>
          </div>
        </swiper-slide>
        <!-- Mas comentarios -->
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/david.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>David espinosa fragoso</h6>
            <p>
              La comida y bebida super rica, buen ambiente para pasarla bien con amigos, excelente servicio
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="item-slider">
          <div class="card-opinion">
            <img src="assets/cesar.png" alt="" />
            <div class="card-stars">
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i
              ><i class="fa-solid fa-star"></i>
            </div>
            <h6>César Ángel Giovanni Pérez Moreno</h6>
            <p>
              Las mejores alitas de la zona, las micheladas muy buenas, los precios bastante accesibles, muy recomendado!!!
            </p>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div> 