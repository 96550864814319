<div class="contacto" id="ubicacion">
  <div class="item-contacto">
    <h2>ENCÚENTRANOS EN</h2>
    <p>
      Avenida Tláhuac #1577 locales 98,100 y 101 <br />
      dentro del centro comercial Tulyehualco.
    </p>
  </div>
  <div class="item-contacto">
    <!-- <img src="assets/mapa.png" alt="" /> -->
    <iframe
      class="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.32573651688637!2d-99.07363388969199!3d19.316614212585222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce02418a1d8d77%3A0x1e92e4700180cb52!2sPlaza%20Grill!5e0!3m2!1ses-419!2smx!4v1730139198130!5m2!1ses-419!2smx"
      style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
    </iframe>
  </div>
</div>