<div class="nosotros" id="nosotros">
  <div class="item-nosotros">
    <img src="assets/logo-nosotros.png" alt="" />
  </div>
  <div class="item-nosotros">
    <h2>¡Famosos por nuestras hamburguesas!</h2>
    <p>
      Plaza Grill inicia su camino en 2005 con un deseo de ofrecer platillos
      recién hechos, frescos y con sazón diferente, además de ofrecer una rica y
      vasta gama de cervezas artesanales e importadas, en un ambiente rockero y
      relajado, buscamos ofrecerles una muy agradable experiencia cada que nos
      visitan.
    </p>
  </div>
</div>
