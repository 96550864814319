<div class="box-video">
  <video
    src="assets/video/comida.mp4"
    autoplay
    loop
    width="100%"
    class="video-descktop"
  ></video>
  <img src="assets/hamburguesa-vertical.jpg" alt="" class="video-mobil" />
  <div class="box-text">
    <h1>Plaza Grill</h1>
    <h2>¡Disfruta con tu familia y amigos de nuestras delicias!</h2>
  </div>
</div>
