import {
  animate,
  style,
  transition,
  trigger,
  AnimationEvent,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

interface Imagenes {
  id: number;
  imageSrc: string;
}

@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrl: './galery.component.css',
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({ transform: 'scale(0.5)' }),
        animate('150ms', style({ transform: 'scale(1)' })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0.8 })),
      ]),
    ]),
  ],
})
export class GaleryComponent implements OnInit {
  @Input() Images: Imagenes[] = [];
  @Input() showCount = false;
  previewImage = false;
  showMask = false;
  currentLigthboxImage: Imagenes = this.Images[0];
  currentIndex = 0;
  controls = true;
  totalImageCount = 0;
  constructor() {}
  ngOnInit(): void {
    this.totalImageCount = this.Images.length;
  }
  onPreviewImage(index: number): void {
    this.showMask = true;
    this.previewImage = true;
    this.currentIndex = index;
    this.currentLigthboxImage = this.Images[index];
  }
  onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.showMask = false;
    }
  }
  onClosePreview() {
    this.previewImage = false;
  }
  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.Images.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLigthboxImage = this.Images[this.currentIndex];
  }
  prev(): void {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.Images.length - 1;
    }
    this.currentLigthboxImage = this.Images[this.currentIndex];
  }
}
