<div id="alitas">
  <div class="super-container3">
    <img src="assets/texto-alitas-boneles.png" alt="" class="menu-alitas" />

    <swiper-container
      class="mySwiper container-slider3"
      effect="coverflow"
      grab-cursor="true"
      centered-slides="true"
      slides-per-view="auto"
      coverflow-effect-rotate="0"
      coverflow-effect-stretch="0"
      coverflow-effect-depth="400"
      coverflow-effect-modifier="2"
      coverflow-effect-slide-shadows="false"
    >
      <swiper-slide
        class="item-slider3"
        *ngFor="let date of Alita; let i = index; let last = last"
      >
        <img src="{{ date.imagenAlita }}" alt="" class="img-slider-alita" />
        
        <button 
          (click)="openModalAlita(i)"
          *ngIf="!last"
        >
          Saber más
        </button>
      </swiper-slide>
    </swiper-container>
    <div
      class="modal-producto"
      *ngIf="modalAlita && indexAlita == currentModalAlita.id"
    >
      <i
        class="fa-solid fa-xmark btn-cerrar-modal"
        (click)="closeModalAlita()"
      ></i>
      <div class="modal-body">
        <div class="card-producto">
          <div class="card-image">
            <img
              src="{{ currentModalAlita.imgModal }}"
              alt=""
              class="img-alitas"
            />
          </div>

          <div class="descripcion-hamburguesa">
            <a href="#">Comprar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
