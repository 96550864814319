<div class="container-footer">
  <div class="item-footer">
    <img src="assets/logo-nosotros.png" alt="" />
  </div>
  <div class="item-footer">
    <h4>Horarios</h4>
    <p>Lunes a Viernes de 1:00pm a 9:00pm</p>
    <p>Domingos de 1:00pm a 8:00pm</p>
    <a href="tel: 5558631345"
      ><img src="assets/phone.png" alt="" /> <span>5558631345</span>
    </a>
  </div>
  <div class="item-footer">
    <h2>Síguenos en nuestras redes</h2>
    <div class="redes">
      <a
        href="https://www.facebook.com/PlazaGrillmx?mibextid=ZbWKwL"
        target="_blank"
        ><img src="assets/facebook.png" alt=""
      /></a>
      <a
        href="https://www.instagram.com/plaza.grill?igsh=MWpraDJicjN4aXBxNg=="
        target="_blank"
        ><img src="assets/instagram.png" alt=""
      /></a>
    </div>
  </div>
</div>
