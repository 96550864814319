<nav>
  <div class="logo-menu">
    <img src="assets/logo-plaza-grill.png" alt="" />
  </div>
  <ul class="menu" [ngClass]="collapsed ? 'menuActive' : ''">
    <li><a href="#inicio" (click)="verMenu()">Inicio</a></li>
    <li><a href="#nosotros" (click)="verMenu()">Nosotros</a></li>
    <li><a href="#ubicacion" (click)="verMenu()">Ubicación</a></li>
    <li><a href="#promociones" (click)="verMenu()">Promociones</a></li>
    <li>
      <a href="/assets/menu-plaza-grill.pdf" target="_blank" (click)="verMenu()"
        >Menú</a
      >
    </li>
  </ul>
  <div id="menu_on" (click)="verMenu()" [ngClass]="btnMenu ? 'btnActive' : ''">
    <span></span>
    <span></span>
    <span></span>
  </div>
</nav>
