import { Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent {
  collapsed: boolean = false;
  btnMenu: boolean = false;
  verMenu() {
    this.collapsed = !this.collapsed;
    this.btnMenu = !this.btnMenu;
  }
}
