import { Component } from '@angular/core';

@Component({
  selector: 'app-carta-menu',
  templateUrl: './carta-menu.component.html',
  styleUrl: './carta-menu.component.css'
})
export class CartaMenuComponent {

}
