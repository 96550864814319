<div class="delivery">
  <div class="item-delivery">
    <div class="texto-delivery">
      <p>Plaza grill</p>

      <p>Delivery</p>

      <p>Empieza tu pedido</p>
    </div>

    <img src="assets/moto.png" alt="" />
  </div>
  <div class="item-delivery">
    <a
    class="item-delivery-a"
    target="_blank" 
    href="https://www.ubereats.com/mx/store/plaza-grill/Mcc1ypBdSxSEguEG5eeYFg?srsltid=AfmBOorAHYRULwbiSTM_lGFgbzs-uwBJDBhGOgVAyHVC6AH6Pr34SPlq"
    >
      <img 
        src="assets/uber.png" alt="" 
        />
    </a>
    <a 
    class="item-delivery-a"
    target="_blank"
    href="https://www.rappi.com.mx/restaurantes/1923211938-plaza-grill">
      <img src="assets/rappi.png" alt="" />
    </a>
  </div>
</div>
