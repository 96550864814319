import { Component } from '@angular/core';

@Component({
  selector: 'app-box-video',
  templateUrl: './box-video.component.html',
  styleUrl: './box-video.component.css'
})
export class BoxVideoComponent {

}
