<div class="carta-menu" id="menu">
  <div class="item-carta-menu">
    <div class="body-carta-menu">
      <img src="assets/texto-cervezas.png" alt="" class="title-carta-menu" />
      <img src="assets/chevez.png" alt="" class="cerveza" />
    </div>
    <a href="#cervezas">
      <img src="assets/btn-menu.png" alt="" class="btn-menu" />
    </a>

    <h5>Solo cervezas</h5>
  </div>
  <div class="item-carta-menu">
    <div class="body-carta-menu">
      <img
        src="assets/texto-hamburguesas.png"
        alt=""
        class="title-carta-menu2"
      />
      <img src="assets/hamburguesa.png" alt="" class="hamburguesa" />
    </div>
    <a href="#hamburguesas">
      <img src="assets/btn-menu.png" alt="" class="btn-menu" />
    </a>

    <h5>Solo hamburguesas</h5>
  </div>
  <div class="item-carta-menu">
    <div class="body-carta-menu">
      <img src="assets/texto-alitas.png" alt="" class="title-carta-menu3" />
      <img src="assets/alitas.png" alt="" class="alitas" />
    </div>
    <a href="#alitas">
      <img src="assets/btn-menu.png" alt="" class="btn-menu" />
    </a>

    <h5>Solo alitas</h5>
  </div>
</div>
