<app-menu></app-menu>
<app-box-video></app-box-video>
<app-nosotros></app-nosotros>
<app-carta-menu></app-carta-menu>
<app-delivery></app-delivery>
<app-galery [Images]="data" [showCount]="true"></app-galery>
<app-slider-hamburguesas [Hamburguesa]="info"></app-slider-hamburguesas>
<app-slider-cervezas [Cerveza]="texto"></app-slider-cervezas>
<app-slider-alitas [Alita]="date"></app-slider-alitas>
<app-opiniones></app-opiniones>
<app-contacto></app-contacto>
<app-footer></app-footer>

<a
  class="btn-flotante"
  href="https://api.whatsapp.com/send?phone=525558631345"
  target="_blank"
>
  <img
    class="btn-flotante-img" 
    src="../assets/whatsapp.png" 
    alt="Whatsapp"
    title="Whatsapp"
  >
</a>